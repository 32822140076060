import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import {
  ErvErrorResponse,
  InsurancePerson,
  InsurancePersonRequest,
  InsurancePersonResponse,
  InsurancePremium,
  InsurancePremiumRequest,
} from './erv-api.models';
import { Observable, catchError, delay, map, of } from 'rxjs';
import { Attempt } from '@miduca/core';
import { trimEnd } from '../../../core/src/lib/helpers/url';

export const ERV_API_INJECT_TOKEN = 'erv-api-url';

@Injectable({
  providedIn: 'root',
})
export class ErvApiService {
  private apiUrl: string;

  constructor(
    private http: HttpClient,
    @Inject(ERV_API_INJECT_TOKEN) url: string
  ) {
    this.apiUrl = trimEnd(url, '/');
  }

  getPremiums(
    request: InsurancePremiumRequest
  ): Observable<Attempt<InsurancePremium[]>> {
    return this.http
      .post<InsurancePremium[]>(`${this.apiUrl}/praemien`, request)
      .pipe(
        map(response => {
          // TODO: validate response
          return Attempt.succeeded(response);
        }),
        catchError(error => {
          console.log('Exception', error);
          if (error instanceof HttpErrorResponse && error.error?.code) {
            console.log('HTTPErrorResponse', error);
            return of(
              Attempt.failed<InsurancePremium[]>(
                `Server Error ${error.error.code}: ${error.error.message}`
              )
            );
          }
          return of(Attempt.failed<InsurancePremium[]>(error.message));
        })
      );
  }

  getPeople(
    request: InsurancePersonRequest
  ): Observable<Attempt<InsurancePerson[]>> {
    return this.http
      .post<InsurancePersonResponse>(`${this.apiUrl}/versicherte`, request)
      .pipe(
        map(response => {
          // TODO: validate response
          return Attempt.succeeded(response.versicherte ?? []);
        }),
        catchError(error => {
          console.log('Exception', error);
          if (error instanceof HttpErrorResponse && error.error?.code) {
            console.log('HTTPErrorResponse', error);
            return of(
              Attempt.failed<InsurancePerson[]>(
                `Server Error ${error.error.code}: ${error.error.message}`
              )
            );
          }
          return of(Attempt.failed<InsurancePerson[]>(error.message));
        })
      );
  }
}
