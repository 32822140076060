export class Attempt<T = any> {
  messages?: string[];
  succeeded: boolean = false;
  result?: T;

  static succeeded<T = any>(result: T): Attempt<T> {
    return {
      succeeded: true,
      result: result,
    };
  }

  static failed<T = any>(...messages: string[]): Attempt<T> {
    return {
      succeeded: false,
      messages: messages,
    };
  }
}
