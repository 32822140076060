export function trimEnd(target: string, char: string) {
  if (target.endsWith(char)) {
    return target.substring(0, target.length - 1);
  }
  return target;
}

export function makeUrlSafe(value: string) {
  // Remove whitespaces
  value = value.replace(/\s+/g, '-');

  // Replace special characters with underscores
  value = value.replace(/[^\w\s]/gi, '-');

  return value.toLowerCase();
}
